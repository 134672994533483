import * as React from 'react'

import Layout from 'components/Layout'
import Seo from 'components/seo'

const NotFoundPage = () => (
	<Layout>
		{/* <Seo title='404: Not found' /> */}
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
			<div>
				<h1>404: Not Found</h1>
			</div>
			<div>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</div>
		</div>


	</Layout >
)

export default NotFoundPage
